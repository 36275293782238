// TagsList.tsx
import React from "react";

interface Tag {
  id: number;
  slug: string;
  name: string;
}

interface TagsListProps {
  tags: Tag[];
  error: string | null;
}

const TagsList: React.FC<TagsListProps> = ({ tags, error }) => {
  return (
    <div className="row pt-235">
      <h3>Tag</h3>
      <div className="name-tag-12">
        {error && <p className="error-message">{error}</p>}
        {tags?.length > 0 ? (
          tags?.map((tag) => (
            <p key={tag.id}>
              <a href={`#${tag?.slug}`}>{tag?.name}</a>
            </p>
          ))
        ) : (
          <p>No tags available</p>
        )}
      </div>
    </div>
  );
};

export default TagsList;
