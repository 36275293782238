import { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Index = ({ onChange }: any) => {
  const recaptchaRef = useRef();
  return (
    <div>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey="6LcMaU8qAAAAACQNgFoiKqY9GLTIaInw5O1wZov_"
        onChange={onChange}
      />
    </div>
  );
};

export default Index;
