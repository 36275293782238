export const features = [
    { id: 1, text: 'Fun and engaging learning environment' },
    { id: 2, text: 'Practical, real-world projects that simulate industry scenarios' },
    { id: 3, text: 'Connect with peers, mentors, and industry professionals.' },
    { id: 4, text: 'Training on the latest tools, software, and technologies.' },
    { id: 5, text: 'Guidance from experienced professionals.' },
    { id: 6, text: 'Career guidance for prospects.' },
    { id: 7, text: 'Internship opportunities.' },
    { id: 8, text: 'Job placement assistance.' },
  ];
  