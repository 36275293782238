export const truncateDescription = (text, maxLength) => {
    if (text.length <= maxLength) {
        return text;
    }

    // Find the position of the last space within the maxLength limit
    let truncated = text.substring(0, maxLength);
    const lastSpaceIndex = truncated.lastIndexOf(' ');

    // If a space is found, truncate at that position, otherwise just use the maxLength
    if (lastSpaceIndex !== -1) {
        truncated = truncated.substring(0, lastSpaceIndex);
    }

    return truncated + '...';
};
