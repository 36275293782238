import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { caseStudies } from "./data";
import { Aa, CaseStudyBg, color1, color2, Das_web_img_1, Das_web_img_2, EE4B04, FFFFFF, icontag, projectbar, ux04 } from "src/assets";
import { Helmet } from "react-helmet";
import './style.css'
import Marquee from "react-fast-marquee";
import { truncateDescription } from "src/helpers";
import { NewCaseStudies } from "./NewCaseStudies";
const CaseStudys = () => {
  const { slug } = useParams();
  const [caseStudy, setCaseStudy] = useState(null);
  const [randomCards, setRandomCards] = useState([]);

  useEffect(() => {
    const fetchedCaseStudy = NewCaseStudies.find((study) => study.slug === slug);
    setCaseStudy(fetchedCaseStudy);
    const otherCaseStudies = NewCaseStudies.filter((study) => study.slug !== slug);
    const shuffledStudies = otherCaseStudies.sort(() => 0.5 - Math.random());
    const twoRandomStudies = shuffledStudies.slice(0, 2);
    setRandomCards(twoRandomStudies);
  }, [slug]);

  if (!caseStudy) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Helmet>
        <title>
          {caseStudy?.title ?? "Case Studies"} | Ditinus
          Technology
        </title>
      </Helmet>
      <main className="bg-height4 bg-kpo-bpo our-portfolio1 poortfolio-single-page position-relative">
        <img src={CaseStudyBg} className="img-fluid cs-PageBg w-100" style={{ opacity: 0.4, zIndex: -1 }} alt="img" />
        <section className="cs-section mb-0 mb-sm-0 mb-md-5 mb-lg-3 mb-xl-3 py-5 mt-2 mt-lg-5 mt-md-3 mt-xl-5">
          <div className="container">
            <div className="row">
              <h1 className="heading-223">{caseStudy.title}</h1>
            </div>
          </div>
        </section>
        <section className="our-portfoliotext">
          <div className="container">
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 ">
                <figure>
                  <img
                    className="w-100 "
                    src={caseStudy?.image}
                    alt="Portfolio Details"
                  />
                </figure>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 d-flex flex-column justify-content-center gap-0 gap-sm-0 gap-md-0 gap-lg-4 gap-xl-4">
                <div className="col-lg-12 col-md-12 col-sm-12 mt-3 mt-lg-0 mt-xl-0">
                  <h2 className="cs-about-header text-white">About {caseStudy.title}</h2>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 ">
                  <p className="text-white cs-about-desc">
                    {caseStudy.description}
                  </p>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 fw-light">
                  <a
                    type="button"
                    href="#contact-form"
                    className="transition text-nowrap rounded-pill fill-btn outline-btn2 iconBtn col-6 col-sm-6 col-md-6 col-lg-5 col-xl-4 d-flex justify-content-center py-1 "
                  >
                    Visit Website{" "}
                    {/* <i className="bi bi-arrow-up-short ms-1 fs-3" /> */}
                  </a>
                </div>
              </div>
            </div>

            {/* Right Menu - Two Random Cards */}
          </div>
        </section>

        <section className="cs-section">
          <div className="container">
            <div className=" row justify-content-center  ">

              <div>
                <h4 className="cs-about-header text-white text-center" >
                  Project Overview
                </h4>
              </div>
              <div className="mt-5 row  justify-content-center" >
                <div className="row rounded-4 px-3 py-3 px-lg-5 py-lg-5 justify-content-center" style={{
                  background: '#0D72B826'
                }}>
                  <div className="col-12 col-sm-12 col-md-12 col-xl-6 col-lg-6 col-xxl-6 p-0 m-0 d-flex flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row  ">
                    <div className="col-12 col-sm-12 col-md-12 col-xl-11 col-lg-11 col-xxl-11 p-0 m-0 d-flex flex-column gap-3">
                      <h1 className="text-white text-center p-0 m-0 cs-projectCardHeader">
                        Client Request
                      </h1>
                      <p className="text-white text-center p-0 m-0 cs-projectCardDesc">
                        {caseStudy?.clientRequest}
                      </p>
                    </div>
                    <div className="col-1 project-border-container p-0 m-0 d-flex align-items-center align-content-center justify-content-lg-end justify-content-md-center justfy-content-sm-center justify-content-center" >
                      {/* <img src={projectbar} alt="" className="p-0 m-0 " /> */}
                      <div className="d-flex flex-lg-column flex-xl-column flex-md-row align-items-center justify-content-center py-3 py-sm-3 py-md-3 py-lg-0 py-xl-0">
                        <div style={{ width: 10, height: 10, backgroundColor: 'white', borderRadius: '50%' }} />
                        <div className="project-bar" />
                        <div style={{ width: 10, height: 10, backgroundColor: 'white', borderRadius: '50%' }} />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-xl-6 col-lg-6 col-xxl-6 p-0 m-0 d-flex flex-row justify-content-end">
                    <div className="col-12 col-sm-12 col-md-12 col-xl-11 col-lg-11 col-xxl-11 p-0 m-0 d-flex flex-column gap-3">
                      <h1 className="text-white text-center p-0 m-0 cs-projectCardHeader">
                        Our Role
                      </h1>
                      <p className="text-white text-center p-0 m-0 cs-projectCardDesc">
                        {caseStudy?.ourRole}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {(caseStudy?.timeline?.design?.length === 0 || caseStudy?.timeline?.development?.length === 0) && caseStudy?.timeline?.description &&
          <section className="cs-section">
            <div className="container">
              <div className=" row justify-content-center  ">
                <div className="row justify-content-center" >
                  <div className="row rounded-4 px-3 py-3 px-lg-5 py-lg-5 justify-content-center" style={{
                    background: '#0D72B826'
                  }}>
                    <h4 className="cs-about-header text-white text-start p-0 m-0" >
                      Agile Solutions on Demand
                    </h4>
                    <p className="text-white text-start p-0 m-0 cs-projectCardDesc mt-3">
                      {caseStudy?.timeline?.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>}

        {(caseStudy?.timeline?.design?.length > 0 || caseStudy?.timeline?.development?.length > 0) &&
          <section className="cs-section">
            <div className="container">
              <div className="row justify-content-center" >
                <div>
                  <h4 className="cs-about-header text-white text-start" >
                    Timeline
                  </h4>
                </div>
                <div className="mt-3 mt-xl-5 mt-lg-5 row justify-content-center time-line-container px-5 px-sm-5 px-lg-5 px-md-5 px-xl-5 px-xxl-5">

                  <div className="col-12 col-sm-12 col-lg-3 col-md-12 col-xl-3 col-xxl-3 position-relative p-0 m-0">
                    <div className="custom-dashed-line position-absolute" />
                    <div className="col-12 d-flex flex-column justify-content-between h-100 align-items-center px-4">
                      <div><h4 className="py-3 mt-3 mt-lg-0 mt-xl-0 fw-bold" style={{ color: '#C5C5C5' }}>Define</h4></div>
                      <div className="time-line-1 d-flex flex-column gap-3">
                        <div className="timeline-tag"><h4 className="text-white p-0 m-0">Research</h4></div>
                      </div>
                      <div><p className="pt-2" style={{ color: '#C5C5C5' }}>1 to 4 Weeks</p></div>
                    </div>
                  </div>

                  {caseStudy?.timeline?.design?.length > 0 &&

                    <div className="col-12 col-sm-12 col-lg-3 col-md-12 col-xl-3 col-xxl-3 position-relative p-0 m-0">
                      <div className="custom-dashed-line position-absolute" />
                      <div className="col-12 d-flex flex-column justify-content-between h-100 align-items-center px-4">
                        <div><h4 className="py-3 mt-3 mt-lg-0 mt-xl-0 fw-bold" style={{ color: '#C5C5C5' }}>Design</h4></div>
                        <div className="time-line-2 d-flex flex-column gap-3">
                          {caseStudy?.timeline?.design.map((el, i) => (
                            <div className="timeline-tag"><h4 className="text-white p-0 m-0">{el}</h4></div>
                          ))}

                        </div>
                        <div><p className="pt-2" style={{ color: '#C5C5C5' }}>4 to 10 Weeks</p></div>
                      </div>
                    </div>
                  }

                  <div className="col-12 col-sm-12 col-lg-3 col-md-12 col-xl-3 col-xxl-3 position-relative p-0 m-0">
                    <div className="custom-dashed-line position-absolute" />
                    <div className="col-12 d-flex flex-column justify-content-between h-100 align-items-center px-4">
                      <div><h4 className="py-3 mt-3 mt-lg-0 mt-xl-0 fw-bold" style={{ color: '#C5C5C5' }}>Development</h4></div>
                      <div className="time-line-3 d-flex flex-column gap-3">
                        {caseStudy?.timeline?.development.map((el, i) => (
                          <div className="timeline-tag"><h4 className="text-white p-0 m-0">{el}</h4></div>
                        ))}
                      </div>
                      <div><p className="pt-2" style={{ color: '#C5C5C5' }}>10 to 13 Weeks</p></div>
                    </div>

                  </div>

                  <div className="col-12 col-sm-12 col-lg-3 col-md-12 col-xl-3 col-xxl-3 position-relative p-0 m-0">
                    <div className="custom-dashed-line position-absolute" />
                    <div className="col-12 d-flex flex-column justify-content-between h-100 align-items-center px-4">
                      <div><h4 className="py-3 mt-3 mt-lg-0 mt-xl-0 fw-bold" style={{ color: '#C5C5C5' }}>Deployment</h4></div>
                      <div className="time-line-4 d-flex flex-column gap-3">
                        <div className="timeline-tag"><h4 className="text-white p-0 m-0">Go Live</h4></div>
                      </div>
                      <div><p className="pt-2" style={{ color: '#C5C5C5' }}>13 to 14 Weeks</p></div>
                    </div>
                    <div className="custom-dashed-line2 position-absolute left-1" />
                  </div>
                </div>
              </div>
            </div>
          </section>}

        <section className="cs-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="row ">
                <div className="col-12 rounded-5 py-4 px-3 px-sm-4 px-md-4 px-lg-5 px-xl-5 py-sm-2 py-md-4 py-lg-3 d-flex flex-column gap-3" style={{ background: '#0D72B8' }}>
                  <h4 className="cs-about-header text-white text-start" >
                    Challenges
                  </h4>
                  <ul className="p-0 ps-3 text-white d-flex flex-column gap-2">
                    {caseStudy?.challenges?.map((el, i) => (
                      <li className="p-0 text-white challange-desc" key={i}>
                        {el}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="cs-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="row">

                <h4 className="cs-about-header text-white text-start p-0 m-0" >
                  Services Provided
                </h4>
                <div className="row g-3 d-flex flex-column flex-lg-row flex-xl-row gap-3 gap-xl-4 gap-lg-4 mt-3">
                  <a
                    type="button"
                    href="/ui-ux-design-services-company"
                    className="transition text-nowrap fill-btn outline-btn2 iconBtn col-8 col-sm-8 col-md-6 col-lg-2 col-xl-2 rounded-4 d-flex justify-content-center"
                  >
                    Design{" "}
                    <i className="bi bi-arrow-up-short ms-1 fs-3" />
                  </a>
                  <a
                    type="button"
                    href="/software-development-services-company"
                    className="transition text-nowrap fill-btn outline-btn2 iconBtn col-8 col-sm-8 col-md-6 col-lg-2 col-xl-2 rounded-4 d-flex justify-content-center"
                  >
                    Development{" "}
                    <i className="bi bi-arrow-up-short ms-1 fs-3" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {caseStudy?.websiteImages?.length > 0 && (
            <div className="row web-image-marque">
              <Marquee pauseOnHover speed={200} className="" >
                <div className="d-flex flex-row flex-nowrap justify-content-between align-items-between gap-3">
                  {caseStudy?.websiteImages?.map((el, i) => (
                    <img src={el} className={`img-fluid ${i == 0 ? 'ps-3' : ''}`} alt="" />
                  ))}
                </div>
              </Marquee>
            </div>)}
        </section>

        <section className="cs-section">
          <div className="container">
            <div className="row justify-content-center">

              {caseStudy?.websiteImages?.length > 0 && (
                <div className="row justify-content-between gap-sm-5 gap-md-5 gap-5 gap-lg-0 gap-xl-0">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 rounded-5 py-4 px-4" style={{ background: "#0D72B826" }}>
                    <div className="row">
                      <h4 className="cs-about-header text-white text-start" >
                        Color Palette
                      </h4>
                    </div>
                    <div className="row d-flex flex-row py-4 justify-content-between ">
                      {caseStudy?.colorPallettes?.map((el, i) => (
                        <div className="col-3 d-flex flex-column justify-content-center gap-3 align-items-center" key={i}>
                          <div className="pallette" style={{ background: el }} />
                          <p className="text-white mt-2 pallete-title">{el}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="row col-xl-6 col-lg-6 justify-content-end p-0 m-0">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-11 col-xl-11 rounded-5 position-relative py-4 px-4" style={{ background: "#0D72B826" }}>
                      <div className="position-absolute" style={{ right: 8, bottom: 8, zIndex: -1 }}>
                        <img src={Aa} alt="" className="img-fluid h-50" />
                      </div>
                      <div className="row d-flex flex-column justify-content-between h-100 ps-2">
                        <h4 className="cs-about-header text-white text-start p-0 m-0" >
                          Typography
                        </h4>
                        <h3 className="Font-Card-Text  text-white text-start p-0 m-0 mt-4" >
                          {caseStudy?.fontFamily}
                        </h3>
                        <ul className="p-0 ps-4 text-white d-flex flex-column gap-2 font-style-points mt-4">
                          {caseStudy?.fontStyles?.map((el, i) => (
                            <li className="p-0 text-white fw-medium" key={i}>
                              {el}
                            </li>
                          ))}
                        </ul>
                      </div>

                    </div>
                  </div>
                </div>
              )}
              <div className={`row ${caseStudy?.websiteImages?.length > 0 ? "mt-5" : ""}`}>
                <h4 className="cs-about-header text-white text-start p-0 m-0" >
                  Result
                </h4>
                <div className="col-lg-12 col-md-12 col-sm-12 text-white cs-about-desc mt-4 p-0 m-0 ">
                  {caseStudy.results.map((el, i) => (
                    <>
                      <p className="p-0 m-0 mt-2">
                        {el}
                      </p>
                    </>
                  ))}

                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="cs-section">
          <div className="container">

            <div className="row">
              <h4 className="cs-about-header text-white text-start" >
                Techstacks Used:
              </h4>
            </div>
            <div className="ps-3 mt-3 d-flex flex-row flex-wrap gap-5 gap-lg-5 gap-xl-5 gap-md-5 col-12 col-md-12  col-sm-6 col-lg-12 ">
              {caseStudy?.teckStackUsed && caseStudy.teckStackUsed.map((techs, index) => (
                <div className="mt-3 d-flex flex-wrap">
                  <img key={index} src={techs} className="techstack" alt="" />
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="cs-section">
          <section className="" style={{ background: "#0D72B8" }} >
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center py-4 " >
                <div className="container col-12 d-flex flex-column justify-content-center align-items-center gap-3 get-a-qoute-banner ">
                  <h1 className="text-white p-0 m-0 text-center col-12 col-lg-10 col-xl-10">
                    Are you curious how our tech pros can turn the tide for your brand too?
                  </h1>
                  <p className="p-0 m-0 text-white text-center">Don’t wait—reach out to us today!</p>
                  <form className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 d-flex flex-column gap-3 mt-2">
                    <input
                      type="email"
                      name="email"
                      placeholder="E-mail"
                      className="formInput m-0 w-100 rounded-5"
                    />
                    <button
                      type="submit" className="fill-btn outline-btn2 w-100 rounded-pill">
                      Get a Quote
                    </button>
                  </form>
                </div>

              </div>
            </div>
          </section>
        </section>


        <section className="cs-section">
          <div className="container ">
            <div className="row justify-content-center">

              <div className="row">
                <h4 className="cs-about-header text-white text-start p-0 m-0" >
                  Our Similar Projects
                </h4>
              </div>
              <div className="row">
                <div className="right-menu">
                  <div className="row g-lg-4 g-xl-4 g-sm-4 g-md-4 g-5">
                    {randomCards.map((e, index) => (
                      <div className="col-lg-6 col-md-6 col-sm-12" key={index}>
                        <Link to={`/case-study/${e.slug}`}>
                          <div className="card">
                            <figure>
                              <img
                                className="w-100"
                                src={e.image2}
                                alt={`Portfolio Image ${index + 1}`}
                              />
                            </figure>
                            <div className="d-flex flex-row  gap-3 flex-wrap p-0 m-0 mt-0 jusitfy-content-start " key={index}>
                              {e?.keywords.map((el, index) => (
                                <div className="d-flex flex-row gap-1">
                                  <img src={icontag} loading="lazy" className="p-0" alt="" />
                                  <p className="text-white p-0 m-0 text-wrap fw-normal caseStudies-tag">
                                    {el}
                                  </p>
                                </div>
                              ))}
                            </div>
                            <div className="label-tag py-2">
                              <h2>{e?.title}</h2>
                            </div>
                            <p className="text-white p-0 m-0 fw-normal">
                              {e?.description ? truncateDescription(e.description, 90) : ""}
                            </p>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div >
  );
};

export default CaseStudys;

// <div className="row g-3 p-ding2">
//   <div className="col-lg-12 col-md-12 col-sm-12">
//     <h2 className="heading-02">{caseStudy?.projectRequirement}</h2>
//   </div>
//   <div className="col-lg-12 col-md-12 col-sm-12 text-WH">
//     <p>{caseStudy?.requirementDes}</p>
//   </div>
// </div>

// <div className="row p-ding2 text-WH ">
//   {caseStudy.requirements.map((requirement, index) => (
//     <div className="col-lg-6 col-md-6 col-sm-12" key={index}>
//       <div className="row">
//         <div className="col-1">
//           <img loading="lazy"
//             className="img-fluid"
//             src={ux04}
//             alt="Requirement Icon"
//           />
//         </div>
//         <div className="col-11">
//           <p>{requirement}</p>
//         </div>
//       </div>
//     </div>
//   ))}
//   {caseStudy.requirements2.map((requirement, index) => (
//     <div className="col-lg-6 col-md-6 col-sm-12" key={index}>
//       <div className="row">
//         <div className="col-1">
//           <img
//             className="img-fluid"
//             src={ux04}
//             alt="Requirement Icon"
//           />
//         </div>
//         <div className="col-11">
//           <p>{requirement}</p>
//         </div>
//       </div>
//     </div>
//   ))}
// </div>

// <div className="row g-3 p-ding2">
//   <div className="col-lg-12 col-md-12 col-sm-12">
//     <h2 className="heading-02">Challenges Faced:</h2>
//   </div>
//   <div className="col-lg-12 col-md-12 col-sm-12 text-WH">
//     {caseStudy?.challenges2 && caseStudy.challenges2.map((requirement, index) => (
//       <div className="col-lg-12 col-md-12 col-sm-12" key={index}>
//         <div className=" d-flex flex-row col-12 flex-wrap gap-2 gap-lg-4">
//           <div className="" >
//             <img loading="lazy"
//               className="img-fluid "
//               src={ux04}
//               alt="Requirement Icon"
//             />
//           </div>
//           <div className="col-10 ">
//             <p>{requirement}</p>
//           </div>
//         </div>
//       </div>
//     ))}

//     {caseStudy?.challenges1 && caseStudy.challenges1.map((challenges, index) => (
//       <div className="col-lg-12 col-md-12 col-sm-12 col-12" key={index}>
//         <div className="row">
//           <div className="col-12">
//             <h5 className="fw-medium">
//               {challenges.header}
//             </h5>
//           </div>
//           <div className="col-12 fw-normal">
//             <p>{challenges.desc}</p>
//           </div>
//         </div>
//       </div>
//     ))}

//   </div>

// </div>
// <div className="row g-3 p-ding2">
//   <div className="col-lg-12 col-md-12 col-sm-12">
//     <h2 className="heading-02">Solution &amp; Outcomes</h2>
//   </div>
//   <div className="col-lg-12 col-md-12 col-sm-12 text-WH">
//     <p>{caseStudy.solution}</p>
//   </div>
// </div>