import axios from "axios";
import { useEffect, useState } from "react";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import { calendaricon41, faceb4, linkicon1 } from "src/assets";
import Search from "src/Components/SearchBar";
import RecentPosts from "src/Components/RecentPosts";
import TagsList from "src/Components/TagsList";
import { Circles } from "react-loader-spinner";
import { Helmet } from "react-helmet";

interface Category {
  id: number;
  name: string;
  slug: string;
}

interface Tag {
  id: number;
  name: string;
  slug: string;
}

interface User {
  id: number;
  username: string;
}

const Index = () => {
  const { slug } = useParams<{ slug: string }>();
  const [blog, setBlog] = useState<any>(null);

  const [error, setError] = useState<string | null>(null);
  const [blogs, setBlogs] = useState<any>([]);
  console.log(blogs, "blogs");

  const [tags, setTags] = useState<Tag[]>([]);

  console.log(tags, "tagstagstagstagstags");

  const [visibleCount, setVisibleCount] = useState<number>(4);
  const [categories, setCategories] = useState<{ [key: string]: number }>({});
  const [loading, setLoading] = useState<boolean>(true);
  const API_URL = process.env.REACT_APP_API_URL;
  const IMAGE_URL = process.env.REACT_APP_IMAGE_API_URL;
  const api = axios.create({
    baseURL: API_URL,
  });
  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 4);
  };

  const fetchBlogs = async () => {
    try {
      const response = await api.get("/blogs");
      console.log(response, "responseresponseresponseresponse");

      return response?.data;
    } catch (error) {
      console.error("Error fetching blogs:", error);
      throw error;
    }
  };

  useEffect(() => {
    const getBlogs = async () => {
      try {
        const blogData = await fetchBlogs();
        setBlogs(blogData?.data);
        const categoryCount: { [key: string]: number } = {};
        blogData?.forEach((blog) => {
          blog?.Categories?.forEach((category) => {
            if (categoryCount[category?.name]) {
              categoryCount[category?.name]++;
            } else {
              categoryCount[category?.name] = 1;
            }
          });
        });
        setCategories(categoryCount);
      } catch (error) { }
    };

    getBlogs();
  }, []);

  useEffect(() => {
    const fetchBlog = async () => {
      if (!slug) return;
      setLoading(true);
      try {
        const url = `${API_URL}/blogs/${slug}`;
        const response = await axios.get<any>(url);
        const blogData = response?.data;
        setBlog(blogData);
        if (typeof blogData?.tags === "string") {
          const tagsArray = blogData.tags.split(",").map((tag, index) => ({
            id: index,
            slug: tag.trim().toLowerCase().replace(/\s+/g, "-"),
            name: tag.trim(),
          }));
          setTags(tagsArray);
        } else {
          setTags(blogData?.tags || []);
        }
      } catch (error) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [slug, API_URL]);

  return (
    <div>
      {loading ? (
        <Circles
          height="90"
          width="90"
          color="#0D72B8"
          ariaLabel="circles-loading"
          wrapperClass="circles"
          visible={true}
        />
      ) : (
        <main className="bg-height4 bg-kpo-bpo blog-single12">
          <Helmet>
            <title>{blog?.meta}</title>
            <meta name="description" content={blog?.discription} />
            <meta name="keyword" content={blog?.keyword} />
          </Helmet>
          {blog?.data && (
            <section className="container">
              <div className="row justify-content-center align-items-center">
                <div className="col-xl-12 text-center top-banner">
                  <h1 className="heading-01">{blog?.data?.title}</h1>
                </div>
              </div>
            </section>
          )}
          <section className="blog-row">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="blog-list-row">
                    <div className="blog-item" data-filter="*">
                      <div className="right-menu p-0">
                        <div className="row g-4">
                          <div className="col-12">
                            <div className="card text-WH">
                              <figure>
                                <img
                                  className="w-100"
                                  src={
                                    `${IMAGE_URL}${blog?.image}` ||
                                    "default-image-url"
                                  }
                                  alt={blog?.title}
                                />
                              </figure>
                              <div className="label-tag">
                                {/* <p>
                                <img    loading="lazy" src={uerprofi45} alt="User profile" />{" "}
                                {blog.User?.username}
                              </p> */}
                                <p>
                                  <img
                                    src={calendaricon41}
                                    alt="Calendar icon"
                                  />{" "}
                                  {new Date(
                                    blog?.created_at
                                  ).toLocaleDateString()}
                                </p>
                              </div>
                              <div className="col-12">
                                <p>{parse(blog?.summernote)}</p>
                              </div>
                              <hr style={{ color: "#CCE3FF" }} />
                              <div className="row side-blog p-0 tag-g">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                  <div className="pt-235 flex2">
                                    <div className="col3">
                                      <h3>Tag</h3>
                                    </div>
                                    <div className="col4">
                                      <div className="name-tag-12">
                                        {tags?.map((tag) => (
                                          <p key={tag?.id}>
                                            <a href="#">{tag?.name}</a>
                                          </p>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                  <div className="pt-235 flex2">
                                    <div className="col3">
                                      <h3>Share</h3>
                                    </div>
                                    <div className="col4">
                                      <div className="name-tag-12 social-media4">
                                        <p>
                                          <a
                                            href="https://www.facebook.com/sharer/sharer.php?u=YOUR_URL_HERE"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            aria-label="Share on Facebook"
                                          >
                                            <img
                                              src={faceb4}
                                              width={11}
                                              alt="Facebook"
                                            />
                                          </a>
                                        </p>
                                        <p>
                                          <a
                                            href="https://twitter.com/intent/tweet?url=YOUR_URL_HERE"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            aria-label="Share on Twitter"
                                          >
                                            <i className="bi bi-twitter-x" />
                                          </a>
                                        </p>
                                        <p>
                                          <a
                                            href="https://www.linkedin.com/sharing/share-offsite/?url=YOUR_URL_HERE"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            aria-label="Share on LinkedIn"
                                          >
                                            <img
                                              src={linkicon1}
                                              width={18}
                                              alt="LinkedIn"
                                            />
                                          </a>
                                        </p>
                                        <p>
                                          <a
                                            href="https://pinterest.com/pin/create/button/?url=YOUR_URL_HERE"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            aria-label="Share on Pinterest"
                                          >
                                            <i className="bi bi-pinterest" />
                                          </a>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <div className="side-blog">
                    <Search
                      onSearch={(query) => console.log("Search query:", query)}
                    />
                    <RecentPosts
                      blogs={blogs}
                      visibleCount={visibleCount}
                      onShowMore={handleShowMore}
                      error={error}
                    />
                    {/* <CategoriesList categories={categories} />
                  <TagsList tags={tags} error={error} /> */}
                    <TagsList tags={tags} error={error} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      )}
    </div>
  );
};

export default Index;
