// RecentPosts.tsx
import React from "react";
import { Link } from "react-router-dom";

interface RecentPostsProps {
  blogs: any[];
  visibleCount: number;
  onShowMore: () => void;
  error: string | null;
}

const RecentPosts: React.FC<RecentPostsProps> = ({
  blogs,
  visibleCount,
  onShowMore,
  error,
}) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const IMAGE_URL = process.env.REACT_APP_IMAGE_API_URL;
console.log(blogs, "blogsblogsblogsblogs}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}");

  return (
    <div className="row p-ding2">
      <h3>Recent Post</h3>
      {error && <p className="error text-white">{error}</p>}
      {blogs.length > 0 ? (
        <>
          {blogs.slice(0, visibleCount).map((blog) => (
            <div key={blog.id} className="blo-side-img">
              <Link to={`/blog/${blog?.slug}`}>
                <div className="row">
                  <div className="col-04">
                    <figure>
                      <img
                        src={`${IMAGE_URL}${blog?.image}`}
                        alt={blog?.title}
                      />
                    </figure>
                  </div>
                  <div className="col-8">
                    <h5>{new Date(blog?.created_at).toLocaleDateString()}</h5>
                    <p>{blog?.title}</p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
          {visibleCount < blogs?.length && (
            <div onClick={onShowMore} className="btn-readmore">
              <span className="transition text-nowrap rounded-pill fill-btn outline-btn2 iconBtn mb-4">
                Show More
                <i className="bi bi-arrow-up-short ms-1 fs-3" />
              </span>
            </div>
          )}
        </>
      ) : (
        <p className="text-white">No recent posts available.</p>
      )}
    </div>
  );
};

export default RecentPosts;
