import { useEffect, useState } from "react";
import Recaptcha from "../Recaptcha";
import "./styles.css";
import { getCountriesList, submitContactForm, submitOutsourceForm } from "src/services";
import Swal from "sweetalert2";
import { Circles } from "react-loader-spinner";
import { useLocation } from "react-router-dom";

const Index = () => {
  const [countries, setCountries] = useState([]);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    organisation: "",
    email: "",
    phone_number: "",
    country: "",
    comments: "",
    enquiry_type: "",
    job_title: ""
  });

  const fetchCountries = async () => {
    const resoponse = await getCountriesList()
    console.log(resoponse);

    if (resoponse) {
      setCountries(resoponse?.data?.countries)
    }
  }

  useEffect(() => {
    fetchCountries()
  }, [])

  const [token, setToken] = useState<string>("");

  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const onChange = (event) => {
    if (errors) {
      setErrors({});
    }
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const onCaptchaChange = (value: any) => {
    if (value) {
      setToken(value);
    }
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (!token) newErrors.verify = "Please Verify.";
    if (!formData.firstname) newErrors.name = "First Name is required.";
    if (!formData.lastname) newErrors.name = "Last Name is required.";
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid.";
    }
    if (!formData.phone_number) {
      newErrors.phone = "Phone number is required.";
    } else if (!/^\d+$/.test(formData.phone_number)) {
      newErrors.phone_number = "Phone number must be digits only.";
    }
    if (!formData.enquiry_type) newErrors.services = "Please select a enquiry type.";
    if (!formData.comments)
      newErrors.comments = "Additional comments are required.";
    if (!formData.country)
      newErrors.country = "Please select a Country name.";
    if (!formData.organisation)
      newErrors.organisation = "Organisation name is required.";
    if (!formData.job_title)
      newErrors.job_title = "Job title name is required.";

    return newErrors;
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        setLoading(true);
        setErrors({});
        const response: any = await submitOutsourceForm(formData);
        if (response) {
          setLoading(false);
          setFormData({
            firstname: "",
            lastname: "",
            organisation: "",
            email: "",
            phone_number: "",
            country: "",
            comments: "",
            enquiry_type: "",
            job_title: ""
          });
          setToken("");
          await Swal.fire({
            title: "Success!",
            text: "Your form has been submitted successfully.",
            icon: "success",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        setLoading(false);
        await Swal.fire({
          title: "Error!",
          text: "There was an issue submitting your form. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };
  const location = useLocation();

  useEffect(() => {
    setErrors({});
  }, [location]);
  useEffect(() => {
    const handleClick = (event) => {
      const targetLink = event.target.closest('a[href="#contact-form"]');

      if (targetLink) {
        const target = document.querySelector("#contact-form");

        if (target) {
          target.scrollIntoView({ behavior: "smooth" });

        }
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (

    <section className="business-mail-section2" id="contact-form">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <h2 className="heading-235">Let's lift up your business!</h2>
          </div>
          <div className="col-xl-9 col-lg-9 col-md-8 col-12 mt-0 mt-sm-0 mt-lg-3 mt-xl-3 d-flex flex-column justify-content-center align-items-center">
            <p className="heading-236 text-center text-sm-center text-lg-start text-xl-start text-md-center col-10 col-sm-10 col-md-12 col-lg-12 col-xl-12">
              Ready to streamline your operations?
            </p>
            <form className="row" onSubmit={handleFormSubmit}>
              <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
                <div className={formData?.enquiry_type ? `` : `select3`}>
                  <select
                    name="enquiry_type"
                    value={formData?.enquiry_type}
                    onChange={onChange}
                    className="m-0"
                  >
                    <option value="" disabled color="">Select Enquiry Type</option>
                    <option value="Inbound Services">Inbound Services</option>
                    <option value="Outbound Services">Outbound Services</option>
                  </select>
                  {errors.enquiry_type && (
                    <span className="error p-0 m-0">{errors.enquiry_type}</span>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-6 col-md-6 col-xl-6 mb-4">
                <input
                  type="text"
                  name="firstname"
                  placeholder="First Name"
                  className="formInput m-0"
                  value={formData.firstname}
                  onChange={onChange}
                />
                {errors.firstname && <span className="error p-0 m-0">{errors.firstname}</span>}
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-6 col-md-6 col-xl-6 mb-4">
                <input
                  type="text"
                  name="lastname"
                  placeholder="Last Name"
                  className="formInput m-0"
                  value={formData?.lastname}
                  onChange={onChange}
                />
                {errors.lastname && (
                  <span className="error p-0 m-0">{errors.lastname}</span>
                )}
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-6 col-md-6 col-xl-6 mb-4">
                <input
                  type="email"
                  name="email"
                  placeholder="E-mail"
                  className="formInput m-0"
                  value={formData?.email}
                  onChange={onChange}
                />
                {errors.email && (
                  <span className="error p-0 m-0 ml-3">{errors.email}</span>
                )}
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-6 col-md-6 col-xl-6 mb-4">
                <input
                  type="text"
                  name="organisation"
                  placeholder="Organisation"
                  className="formInput m-0"
                  value={formData.organisation}
                  onChange={onChange}
                />
                {errors.organisation && <span className="error p-0 m-0">{errors.organisation}</span>}
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-6 col-md-6 col-xl-6 mb-4">
                <input
                  type="text"
                  name="job_title"
                  placeholder="Job Title"
                  className="formInput m-0"
                  value={formData.job_title}
                  onChange={onChange}
                />
                {errors.job_title && (
                  <span className="error p-0 m-0 ml-3">{errors.job_title}</span>
                )}
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-6 col-md-6 col-xl-6 mb-4">
                <input
                  type="tel"
                  maxLength={13}
                  minLength={9}
                  name="phone_number"
                  placeholder="Phone Number"
                  className="formInput m-0"
                  value={formData.phone_number}
                  onChange={onChange}
                />
                {errors.phone_number && (
                  <span className="error p-0 m-0 ml-3">{errors.phone_number}</span>
                )}
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
                <div className={formData?.country ? `` : `select3`}>
                  <select
                    name="country"
                    value={formData.country}
                    onChange={onChange}
                    className="m-0 select-input-box"
                  >
                    <option value="" disabled color="">Country</option>
                    {countries?.length > 0 && countries?.map((el, index) => (
                      <option key={index} value={el?.CountryName}>{el?.CountryName}</option>
                    ))}
                  </select>
                  {errors.country && (
                    <span className="error p-0 m-0">{errors.country}</span>
                  )}
                </div>
              </div>
              <div className="col-lg-12">
                <textarea
                  name="comments"
                  placeholder="Comments*"
                  value={formData.comments}
                  onChange={onChange}
                  className="m-0"
                />
                {errors.comments && (
                  <span className="error p-0 m-0 ml-3">{errors.comments}</span>
                )}
              </div>
              <div className="d-flex justify-content-center mt-4">
                <div className="col-xl-12 col-lg-12 col-md-8 col-12 ">
                  <button
                    type="submit"
                    disabled={loading}
                    style={{

                    }}
                    className={`fill-btn outline-btn2 w-100 rounded-pill `}
                  >
                    {loading ? (
                      <Circles
                        visible={true}
                        height="30"
                        width="30"
                        ariaLabel="hourglass-loading"
                        wrapperClass="d-flex justify-content-center"
                        color="white"
                      />
                    ) : (
                      " Get a Quote"
                    )}
                  </button>
                  <div className="mt-3 d-flex justify-content-center">
                    <Recaptcha onChange={onCaptchaChange} />
                    {errors.verify && (
                      <span className="error p-0 m-0">{errors.verify}</span>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>

  );
};

export default Index;
