import axios from "axios";

const BASE_URl = process.env.REACT_APP_API_URL;

interface ContactFormProps {
  name: string;
  email: string;
  phone: string;
  services: string;
  comment: string;
}
interface outsourceFormProps {
  firstname: string;
  lastname: string;
  organisation: string;
  email: string;
  phone_number: string;
  country: string;
  comments: string;
  enquiry_type: string;
  job_title: string;
}

interface TrainingFormProps {
  name: string;
  email: string;
  phone: string;
  services: string;
  comment: string;
}

export const submitContactForm = async (data: ContactFormProps) => {
  if (data) {
    return await axios
      .post(`${BASE_URl}/contacts/store`, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response: any) => {
        if (response) {
          return response;
        }
      })
      .catch((error: any) => {
        console.log("Error while submiting form. ", error);
      });
  }
};
export const getCountriesList = async () => {

  return await axios
    .get(`${BASE_URl}/countries`, {
      headers: { "Content-Type": "application/json" },
    })
    .then((response: any) => {
      if (response) {
        return response;
      }
    })
    .catch((error: any) => {
      console.log("Error while submiting form. ", error);
    });

};
export const submitOutsourceForm = async (data: outsourceFormProps) => {
  if (data) {
    return await axios
      .post(`${BASE_URl}/store-outsources`, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response: any) => {
        if (response) {
          return response;
        }
      })
      .catch((error: any) => {
        console.log("Error while submiting form. ", error);
      });
  }
};


export const submitTrainingForm = async (data: TrainingFormProps) => {
  if (data) {
    return await axios
      .post(`${BASE_URl}/trainings/store`, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response: any) => {
        console.log(response, "submitTrainingForm");

        if (response) {
          return response;
        }
      })
      .catch((error: any) => {
        console.log("Error while submiting form. ", error);
      });
  }
};

export const submitAppyForm = async (data: any) => {
  if (data) {
    return await axios
      .post(`${BASE_URl}/applied-users/store`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response: any) => {
        if (response) {
          return response;
        }
      })
      .catch((error: any) => {
        console.log("Error while submiting form. ", error);
      });
  }
};

export const getJobList = async (page: number) => {
  return await axios
    .get(`${BASE_URl}/jobs`, {
      headers: { "Content-Type": "application/json" },
    })
    .then((response: any) => {
      if (response) {
        return response;
      }
    })
    .catch((error: any) => {
      console.log("Error while geting Jobs. ", error);
    });
};

export const getJob = async (id: any) => {
  return await axios
    .get(`${BASE_URl}/jobs/${id}`, {
      headers: { "Content-Type": "application/json" },
    })
    .then((response: any) => {
      if (response) {
        return response;
      }
    })
    .catch((error: any) => {
      console.log("Error while geting Jobs. ", error);
    });
};
