import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import './style.css'
import { FloatIcon, logo_D } from "src/assets";
const StickyBarWithIcons = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleMenu = () => setIsExpanded(!isExpanded);

    const iconLinks = [
        { icon: <i className="bi bi-whatsapp" style={{ fontSize: "22px" }}></i>, link: "https://wa.me/+919915578189", label: "Home" },
        {
            icon: <i
                className="bi bi-telephone-fill"
                style={{ fontSize: "22px" }}
            ></i>, link: "tel:+919915578189", label: "Contact"
        },
        {
            icon: <i
                className="bi bi-envelope-fill"
                style={{ fontSize: "22px" }}
            ></i>, link: "mailto:business@ditinustechnology.com", label: "About"
        },
    ];

    const iconVariants = {
        hidden: { opacity: 0, x: 50 },
        visible: (i) => ({
            opacity: 1,
            x: 0,
            transition: { delay: i * 0.1, type: "spring", stiffness: 400 },
        }),
        exit: { opacity: 0, x: 50, transition: { duration: 0.2 } },
    };

    return (
        <div
            className="position-fixed translate-middle-y d-flex align-items-center justify-content-center"
            style={{ zIndex: 1050, top: '50%', right: 0, }}
        >
            {/* Icons */}
            <AnimatePresence>
                {isExpanded && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="d-flex flex-column align-items-center "
                        style={{ marginRight: "-20px", gap: "15px" }}
                    >
                        {iconLinks.map((item, index) => (
                            <motion.a
                                key={index}
                                href={item.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                title={item.label}
                                className="btn btn-outline-primary rounded-circle fill-btn outline-btn d-flex justify-content-center align-items-center"
                                variants={iconVariants}
                                custom={index}
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                style={{
                                    width: "50px",
                                    height: "50px",
                                    textDecoration: "none",
                                    fontSize: "1.5rem",
                                    marginLeft: index === 1 ? '50px' : '120px',
                                    background: '#072751'
                                }}
                            >
                                {item.icon}
                            </motion.a>
                        ))}
                    </motion.div>
                )}
            </AnimatePresence>
            {/* Sticky Toggle Button */}
            {isExpanded ?
                <div
                    className="text-white rounded-circle d-flex align-items-center justify-content-center float_bar_2 me-1"
                    onClick={toggleMenu}

                >
                    <i className="bi bi-x-lg text-white fs-3" />
                </div> :
                <div
                    className=" text-white rounded-circle d-flex align-items-center justify-content-center float_bar "
                    onClick={toggleMenu}
                >
                    <img src={logo_D} alt="" className="p-0 m-0 pe-1 pb-1" />
                </div>}
            {/* <img onClick={toggleMenu} src={FloatIcon} style={{
                width: "100px",
                height: "100px",
                cursor: "pointer",
                opacity: ''
            }} /> */}
        </div>
    );
};

export default StickyBarWithIcons;
