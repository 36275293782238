// src/ScreenshotsDeck.js

import { useEffect, useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import "./style.css";
import screen1 from "../../assets/img/screen1.png";
import screen2 from "../../assets/img/screen2.png";
import screen3 from "../../assets/img/screen3.png";

const screenshots = [screen1, screen2, screen3];

const imageStyles1 = [
  {
    zIndex: 1,
  },
  {
    zIndex: 2,
    marginLeft: "-40px",
    marginBottom: "40px"
  },
  {
    zIndex: 3,
    marginLeft: "-20px",
  },
];
const imageStyles2 = [
  {
    zIndex: 1,
  },
  {
    zIndex: 2,
    marginLeft: "-79px",
  },
  {
    zIndex: 3,
    marginLeft: "-83px",
  },
];

const ScreenshotsDeck = () => {
  const [rotate, setRotate] = useState(false)
  const controls = useAnimation();
  const containerRef = useRef(null);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 991);
  const [isDesktop2, setIsDesktop2] = useState(window.innerWidth >= 768);

  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 991);
    setIsDesktop2(window.innerWidth >= 768);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            controls.start((i) => ({
              opacity: 1,
              y: i === 1 ? -100 : 0,
              x: i * 130 - 50,
              scale: 1,
              transition: {
                duration: 0.5,
                delay: i * 0.3,
              },
            }));
          } else {
            controls.start((i) => ({
              opacity: 0,
              y: 50,
              x: 0,
              scale: 0.8,
              rotate: 0,
              transition: {
                duration: 0.5,
                delay: i * 0.1,
              },
            }));
          }
        });
      },
      { threshold: 0.2 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [controls]);

  return (
    <div className="screenshot-deck-container" ref={containerRef}>
      {screenshots.map((src, index) => (
        <motion.img
          key={index}
          src={src}
          alt={`Screenshot ${index + 1}`}
          className={`screenshot img-fluid mt-xxl-5 mt-xl-0 mt-lg-0 mt-sm-0 mt-md-0 mt-sm-0`}
          custom={index}
          initial={{ opacity: 0, y: 50, x: 10, scale: 0.8, rotate: 0 }}
          animate={controls}
          // onMouseOver={() => setRotate(!rotate)}
          loading="lazy"
          style={isDesktop2 ? imageStyles1[index] : imageStyles2[index]}
        />
      ))}
    </div>
  );
};

export default ScreenshotsDeck;
