import { Link } from "react-router-dom";

function JobItem({ job }) {
  return (
    <div>
      <div className="open-role-box text-WH">
        <div className="row align-items-center">
          <div className="col-lg-10 col-md-9 col-sm-12">
            <div className="row">
              <h3>{job.title}</h3>
            </div>
            <div className="row">
              <div className="name-tag-12">
                {job.tags?.split(",").map((tag, idx) => (
                  <p key={idx}>
                    <span>{tag}</span>
                  </p>
                ))}
              </div>
            </div>
            <div className="row">
              <p>{job.short_description}</p>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-12">
            <div className="btn-readmore justify-content-center">
              <Link
                to={`/career/${job?.id}`}
                className="transition text-nowrap rounded-pill fill-btn outline-btn2 iconBtn"
              >
                Apply Now <i className="bi bi-arrow-up-short ms-1 fs-3" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobItem;
