import './style.css';
import { graphimage, graphlogo1, graphlogo2, graphlogo3, graphlogo4 } from 'src/assets';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useState } from 'react';

const ImageWithCards = () => {
    const [hasEntered, setHasEntered] = useState({
        card1: false,
        card2: false,
        card3: false,
        card4: false
    });

    const { ref: ref1, inView: inView1 } = useInView({
        triggerOnce: true, // Only trigger once
        threshold: 0.5, // Trigger when 50% of the element is visible
        onChange: (inView) => {
            if (inView) setHasEntered(prev => ({ ...prev, card1: true }));
        }
    });

    const { ref: ref2, inView: inView2 } = useInView({
        triggerOnce: true,
        threshold: 0.5,
        onChange: (inView) => {
            if (inView) setHasEntered(prev => ({ ...prev, card2: true }));
        }
    });

    const { ref: ref3, inView: inView3 } = useInView({
        triggerOnce: true,
        threshold: 0.5,
        onChange: (inView) => {
            if (inView) setHasEntered(prev => ({ ...prev, card3: true }));
        }
    });

    const { ref: ref4, inView: inView4 } = useInView({
        triggerOnce: true,
        threshold: 0.5,
        onChange: (inView) => {
            if (inView) setHasEntered(prev => ({ ...prev, card4: true }));
        }
    });

    return (
        <div className="container">
            <div className="row justify-content-center position-relative">
                <h1 className="m-0 p-0 logo-text col-12 position-absolute" data-aos="fade-right">
                    Journey
                </h1>
                <div className="image-container overflow-visible">
                    <img src={graphimage} alt="Image with Cards" className="image p-0 m-0" />

                    {/* Card 1 */}
                    <div className="graph-card-1 position-absolute col-5 col-sm-4 col-md-5 col-xl-3 col-lg-4">
                        <motion.div
                            data-aos="zoom-in-left"

                        >
                            <h5 className="text-white graph-year-title p-0 m-0">2018</h5>
                            <div className="ms-3 ms-sm-3 ms-lg-0 ms-xl-0 ms-md-3">
                                <ul style={{ color: 'white' }} className="m-0 d-flex flex-column gap-1 gap-md-1 gap-xl-2 gap-lg-2 gap-sm-0 gap-md-0 p-0 p-sm-0 p-lg-3 p-xl-3">
                                    <li className="text-white graph-desc fw-light">
                                        Established Ourselves as a leading IT services company.
                                    </li>
                                    <li className="text-white graph-desc fw-light">
                                        Welcomed clients from diverse industries.
                                    </li>
                                    <li className="text-white graph-desc fw-light">
                                        Joined hands with Cyber Ace, our channel partner, expanding our services overseas.
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 gap-2 d-flex flex-row mt-2">
                                <img src={graphlogo4} className="graph-img" alt="" />
                            </div>
                        </motion.div>
                    </div>

                    {/* Card 2 */}
                    <div className="graph-card-2 position-absolute col-5 col-sm-4 col-md-5 col-xl-3 col-lg-4">
                        <motion.div
                            data-aos="zoom-in-left"

                        >
                            <div className="ms-3 ms-sm-3 ms-lg-0 ms-xl-0 ms-md-3">
                                <ul style={{ color: 'white' }} className="m-0 d-flex flex-column gap-0 gap-xl-2 gap-lg-2 gap-sm-0 gap-md-0 p-0 m-0 p-0 p-sm-0 p-lg-3 p-xl-3">
                                    <li className="text-white graph-desc fw-light">
                                        Introduced a range of business process outsourcing services.
                                    </li>
                                    <li className="text-white graph-desc fw-light">
                                        Introduced US based Logistics process
                                    </li>
                                    <li className="text-white graph-desc fw-light">
                                        Launched campus placements for diverse careers.
                                    </li>
                                </ul>
                            </div>
                            <h5 className="text-white graph-year-title p-0 m-0">2022</h5>
                        </motion.div>
                    </div>

                    {/* Card 3 */}
                    <div className="graph-card-3 position-absolute col-5 col-sm-4 col-md-5 col-xl-3 col-lg-4">
                        <motion.div
                            data-aos="zoom-in-right"

                        >
                            <h5 className="text-white graph-year-title p-0 m-0">2023</h5>
                            <div className="ms-3 ms-sm-3 ms-lg-0 ms-xl-0 ms-md-3">
                                <ul style={{ color: 'white' }} className="m-0 d-flex flex-column gap-0 gap-xl-2 gap-lg-2 gap-sm-0 gap-md-0 p-0 m-0 p-0 p-sm-0 p-lg-3 p-xl-3">
                                    <li className="text-white graph-desc fw-light">
                                        Developed advanced healthcare document processing and medical claims processing apps.
                                    </li>
                                    <li className="text-white graph-desc fw-light">
                                        SEO services drove 53% more revenue & 500+ keywords on first page.
                                    </li>
                                    <li className="text-white graph-desc fw-light">
                                        Facilitated collaboration among expanding teams.
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 gap-2 d-flex flex-row mt-2">
                                <img src={graphlogo1} className="graph-img" alt="" />
                                <img src={graphlogo2} className="graph-img" alt="" />
                                <img src={graphlogo3} className="graph-img" alt="" />
                            </div>
                        </motion.div>
                    </div>

                    {/* Card 4 */}
                    <div className="graph-card-4 position-absolute col-5 col-sm-4 col-md-5 col-xl-3 col-lg-4">
                        <motion.div
                            data-aos="zoom-in-right"

                        >
                            <ul style={{ color: 'white' }} className="m-0 d-flex flex-column gap-0 gap-xl-2 gap-lg-2 gap-sm-0 gap-md-0">
                                <li className="text-white graph-desc fw-light">
                                    Expanded our expertise in advanced technologies like AI & ML further strengthening our Tech stack.
                                </li>
                                <li className="text-white graph-desc fw-light">
                                    Launched in-demand industrial training courses.
                                </li>
                                <li className="text-white graph-desc fw-light">
                                    Ranked among the top IT companies in India.
                                </li>
                            </ul>
                            <h5 className="text-white graph-year-title text-end" style={{ marginRight: '10px', marginTop: '10px' }}>
                                2024
                            </h5>
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImageWithCards;